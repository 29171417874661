import $ from '../components/static'
import * as bootstrap from 'bootstrap'

$(document).on("turbolinks:load", function(event) {
  $(event.target).find('[data-toggle="popover"]').each(function (index, el) {
    const popover = new bootstrap.Popover(el)
    $(el).on({
      click: _.method('preventDefault'),
      'shown.bs.popover': _.debounce(() => {
        popover.hide()
      }, 3000)
    })
  })

  $(event.target).find('[data-toggle="tooltip"]').each(function (index, el) {
    new bootstrap.Tooltip(el, {
      boundary: document.body
    })
  })
})

$(function() {
  $(document).on('click', "[data-dismiss=card]", function(event) {
    event.preventDefault()
    $(this).closest(".card").remove()
  })
})